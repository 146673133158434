import React from "react";

import "./Teammate.scss";

export const Teammate = ({ image, title, content }) => {
  return (
    <div className="teammate--root">
      <div className="teammate--img">{image}</div>
      <div className="teammate--about">
        <div className="teammate--title">{title}</div>
        <div className="teammate--content">{content}</div>
      </div>
    </div>
  );
};
