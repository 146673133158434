import React from "react";
import { FullWidth } from "../components/fullWidth/FullWidth";
import { Layout } from "../components/layout/Layout";
import { Teammate } from "../components/team/Teammate";
import { Hr } from "../components/hr/Hr";
import "./team.scss";
import { StaticImage } from "gatsby-plugin-image";

const teammates = [
  {
    image: (
      <StaticImage
        src="../images/Roxanne_photo.png"
        alt="Headshot of Roxanne Hood Lyons"
      />
    ),
    title: "Roxanne Hood Lyons, Strategic Planning, Organizational Development",
    content: (
      <p>
        Roxanne has 40 years experience bolstering outcomes for children as
        founder and ED of a place-based nonprofit and as a public-school
        educator. She is honored to lead this outstanding team. Roxanne serves
        leaders and communities as they reimagine and change systems to disrupt
        disproportionate outcomes for Children of Color so that everyone
        thrives. She provides leadership coaching, facilitation, and strategic
        planning and speaks Spanish.
      </p>
    ),
  },
  {
    image: (
      <StaticImage
        src="../images/Sandra_photo.png"
        alt="Headshot of Sandra Amolo"
      />
    ),
    title: "Sandra Amolo, Organization Development",
    content: (
      <p>
        Sandra has over 10 years of experience in the nonprofit sector. She has
        helped boards develop strategy and vision focused on organization and
        community culture, liberation management coaching, and adapting tools
        for racial equity. Sandra is the Capacity Building Lead for Rooted in
        Vibrant Communities (RVC). She immigrated to the U.S. from Nairobi,
        Kenya as a teenager and speaks Kiswahili, English, and Spanish.
      </p>
    ),
  },
  {
    image: (
      <StaticImage
        src="../images/Betsey_photo.png"
        alt="Headshot of Betsey Archambault"
      />
    ),
    title: "Betsey Archambault, PhD, Fundraising",
    content: (
      <p>
        Betsey is principal with Cooper Consulting and has worked for 15 years
        with nonprofits nationally and internationally, including Nepal, India,
        China, and Chad. A founding executive director, Betsey provides
        fundraising assistance (grant writing, individual gifts, fund
        development planning, and diversification) for small organizations. She
        speaks Japanese proficiently.
      </p>
    ),
  },
  {
    image: (
      <StaticImage src="../images/Jing_photo.png" alt="Headshot of Jing Fong" />
    ),
    title: "Jing Fong, Communications",
    content: (
      <p>
        Jing has 35 years experience in strategic communications, writing, and
        editing. She brings experience working with elected officials, nonprofit
        leaders, and teachers and students on communications, policy, and social
        justice.
      </p>
    ),
  },
  {
    image: (
      <StaticImage
        src="../images/Mamadou_photo.png"
        alt="Headshot of Mamadou"
      />
    ),
    title: "Mamadou Ndiaye, IT",
    content: (
      <p>
        Mamadou is the owner of Awa IT Solutions and a Cisco Certified Lead
        Engineer with 18 years of experience and expertise in building and
        fixing IT systems. He also provides IT to community-based organizations
        as a volunteer. He is fluent in Wolof, French and English.
      </p>
    ),
  },
];

const Team = () => {
  return (
    <Layout>
      <FullWidth>
        <div className="team--root">
          <div className="team--tagline">
            <div>
              RHL Team lead by value of community, generosity, and integrity.
            </div>
          </div>
          <Hr />
          <div className="team--list">
            {teammates.map((t) => (
              <Teammate key={t.title} {...t} />
            ))}
          </div>
          <Hr />
          <div className="team--quote inner-column">
            <quote>
              RHL's team centers race and social justice in all of our
              capacity-building work.
            </quote>
          </div>
          <Hr />
          <div className="team--commitment inner-column">
            <h2>
              Our Commitment
              <br />
              to Equity &amp; Social Justice
            </h2>
            <p>
              RHL Consultants are immigrants, parents, foster parents, survivors
              of intimate partner violence, survivors of the criminal legal
              system and believers in change.
            </p>
            <p>
              Relationships are at the center of our work at RHL Consulting.
              Together, we vision a just world where all children thrive. To
              advance the collective move toward social justice, we:
            </p>
            <ul className="team--tactics">
              <li>
                Listen deeply to understand our clients' cultural practices,
                values, and ways of being, and respectfully incorporate this
                knowledge into our work.
              </li>
              <li>
                Center the lead of BIPOC communities. We believe that community
                leaders hold the wisdom and vision to determine the best ways to
                improve outcomes for their families and neighbors.
              </li>
              <li>
                Together with grassroots organizations we co-create tools and
                design sustainable systems that address the root issues they
                experience.
              </li>
              <li>
                Challenge racist systems by advocating alongside our clients
                within government structures.
              </li>
              <li>
                Broker partnerships to co-create strong and sustainable
                organizations and alliances.
              </li>
              <li>
                Listen, reflect, and hold one another accountable to deepen our
                anti-racist skillset while striving for deeper understanding,
                peace, and collective liberation.
              </li>
            </ul>
          </div>
        </div>
      </FullWidth>
    </Layout>
  );
};

export default Team;
